import { Routes, Route } from "react-router-dom";
import NavBar from './Components/Navigation/NavBar';
import Footer from './Components/Footer/Footer';
import Home from "./Views/Home/Home";
import ServiceAreas from "./Views/ServiceAreas/ServiceAreas";
import Contact from "./Views/Contact/Contact";
import Error from './Components/Error/Error';
import { Helmet } from "react-helmet";
import ScrollToTop from "./Components/ScrollToTop";
import Pricing from "./Views/Pricing/Pricing";
import React, { useEffect, useState } from "react";
import Loader from "./Components/Loader";

function App() {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        // Simulate minimal load delay or use actual asset check
        const timer = setTimeout(() => {
            setIsLoaded(true);
        }, 300); // or check for images/fonts

        return () => clearTimeout(timer);
    }, []);

    if (!isLoaded) {
        return <Loader />;
    }

    return (
        <main style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <div className="root" style={{ flex: 1 }}>
                {/* Global SEO Tags */}
                <Helmet>
                    <title>Well Water Testing & System Inspections in North Idaho & Eastern WA</title>
                    <meta name="description" content="Certified well water testing, GPM flow rate testing, and system inspections. Serving North Idaho and Eastern Washington." />
                    <meta
                        name="keywords"
                        content="well water testing, flow rate testing, system inspections, VA FHA HUD compliance, North Idaho, Eastern Washington"
                    />
                    <meta property="og:title" content="Well Water Testing & System Inspections in North Idaho & Eastern WA" />
                    <meta
                        property="og:description"
                        content="Certified well water testing, GPM flow rate testing, and system inspections. Serving North Idaho and Eastern Washington."
                    />
                    <meta name="author" content="Chinook Water Testing" />
                    <meta property="og:image" content="https://chinookwater.com/chinookLogo.jpg" />
                    <meta property="og:image:alt" content="Chinook Water Testing company logo" />
                    <meta property="og:url" content="https://chinookwater.com/" />
                    <meta property="og:type" content="website" />
                    <meta name="robots" content="index, follow" />
                    <link rel="canonical" href="https://chinookwater.com/" />
                </Helmet>
                <NavBar />
                <ScrollToTop />
                <Routes>
                    <Route path="/athol" element={<ServiceAreas />} />
                    <Route path="/blanchard" element={<ServiceAreas />} />
                    <Route path="/coeur-d-alene" element={<ServiceAreas />} />
                    <Route path="/kellogg" element={<ServiceAreas />} />
                    <Route path="/plummer" element={<ServiceAreas />} />
                    <Route path="/post-falls" element={<ServiceAreas />} />
                    <Route path="/priest-river" element={<ServiceAreas />} />
                    <Route path="/rathdrum" element={<ServiceAreas />} />
                    <Route path="/sandpoint" element={<ServiceAreas />} />
                    <Route path="/spirit-lake" element={<ServiceAreas />} />
                    
                    <Route path="/deer-park" element={<ServiceAreas />} />
                    <Route path="/newport" element={<ServiceAreas />} />
                    <Route path="/spokane" element={<ServiceAreas />} />

                    <Route path="/benewah-county" element={<ServiceAreas />} />
                    <Route path="/bonner-county" element={<ServiceAreas />} />
                    <Route path="/kootenai-county" element={<ServiceAreas />} />
                    <Route path="/pend-oreille-county" element={<ServiceAreas />} />
                    <Route path="/spokane-county" element={<ServiceAreas />} />

                    <Route path="/service-areas" element={<ServiceAreas />} />
                    <Route path="/pricing" element={<Pricing />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/" element={<Home />} />

                    {/* ✅ Catch-all route for bad paths */}
                    <Route path="*" element={<Error />} />
                </Routes>
            </div>
            <Footer />
        </main>
    );
}

export default App;
