import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    card: {
        borderRadius: 10,
        padding: 20,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        [theme.breakpoints.down('md')]: {
            padding: 5
        },
        [theme.breakpoints.down('sm')]: {
            padding: 20
        },
    },
    cardTitle: {
        fontWeight: 'bold',
        fontSize: '1.7em !important',
        color: "#000",
    },
    cardSubtitle: {
        color: '#ba4419',
        fontSize: '1.2em !important',
        fontWeight: 600,
    },
    bulletList: {
        listStyleType: 'disclosure-closed',
        fontSize: '1.2em',
        '& li': {
            marginBottom: 5,
        }
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 60,
        gap: '0px',
        flexWrap: 'wrap',
        padding: 0,
    },
    contactButton: {
        color: '#fff !important',
        fontWeight: 600,
        textTransform: "none",
        minWidth: 130,
        width: '50%',
        height: '100%',
        borderRadius: 0,
        '&:first-of-type': {
            background: 'linear-gradient(45deg, #1f4898, #1365b7)',
            borderRight: '2px solid white',
            borderBottomLeftRadius: '8px',
            borderTopLeftRadius: '8px',
        },
        '&:last-of-type': {
            backgroundColor: '#114796',
            borderBottomRightRadius: '8px',
            borderTopRightRadius: '8px',
            '&:hover, &:focus': {
                backgroundColor: '#0b3879',
            },
        },
    },
}));

export default useStyles;
