import React from "react";
import { Link } from "react-router-dom";
import { Box, Button, Typography } from "@material-ui/core";
import useStyles from "./CallToAction.Styles";

function CallToAction() {
    const classes = useStyles();
    return (
        <Box className={classes.ctaBox}>
            <Typography variant="h5" className={classes.ctaText}>
                Ready to schedule your service? Contact us today!
            </Typography>
            <Button
                variant="contained"
                color="primary"
                size="medium"
                component={Link}
                to="/contact"
                className={classes.ctaButton}
            >
                Contact Us
            </Button>
        </Box>
    );
}

export default CallToAction;