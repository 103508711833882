import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    titleBox: {
        marginTop: 200,
        marginBottom: 150,
        width: 985,
        margin: '175px auto 120px',
        [theme.breakpoints.down('md')]: {
            width: '80%',
            marginLeft: '10%',
            marginBottom: 100,
        },
        [theme.breakpoints.down('xs')]: {
            width: '90%',
            marginLeft: '5%',
            marginTop: 120,
            marginBottom: 75,
        },
    },
    title: {
        marginTop: 100,
        marginBottom: 20,
        textAlign: 'center',
        fontSize: '1.8em',
        color: '#fff',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 600,
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.5em'
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.2em'
        },
    },
    subTitle: {
        textAlign: 'center',
        color: '#fff',
        width: '70%',
        marginLeft: '15%',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        marginTop: 10,
        marginBottom: 20,
        fontWeight: 200,
        fontSize: '1.3em',
        fontStyle: 'italic',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1em',
        },
    },
    pdfBox: {
        marginTop: 10,
        marginBottom: 50,
    },
    ctaBox: {
        textAlign: "center",
        marginTop: "150px",
        marginBottom: "100px",
        padding: theme.spacing(4),
        backgroundColor: "rgba(0,0,0,1)",
        borderRadius: "10px",
    },
    ctaText: {
        marginBottom: theme.spacing(3),
        fontWeight: 600,
        fontSize: "1.5em",
        color: "coral",
    },
    ctaButton: {
        fontSize: "1em",
        padding: theme.spacing(1.5, 4),
    },
}));

export default useStyles;
